import React from "react";


function Navbar() {
      
	return (
        <header className="slogan-wrapper">
            <h3 >La mia esperienza, la tua cura</h3>
        </header>
	);
}

export default  Navbar;
